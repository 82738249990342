var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-iam", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        _setup.ValidationObserver,
        { ref: "obs" },
        [
          _c(
            "v-form",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(_setup.ValidationProvider, {
                            attrs: { name: "Key", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-text-field", {
                                      class: { noBorders: _setup.readOnly },
                                      attrs: {
                                        "hide-details": "auto",
                                        label: "Key",
                                        placeholder: "key",
                                        "persistent-placeholder": "",
                                        outlined: !_setup.readOnly,
                                        "error-messages": errors,
                                        readonly: _setup.readOnly,
                                      },
                                      model: {
                                        value: _setup.selectedData.key,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.selectedData,
                                            "key",
                                            $$v
                                          )
                                        },
                                        expression: "selectedData.key",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(_setup.ValidationProvider, {
                            attrs: { name: "Naam", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-text-field", {
                                      class: { noBorders: _setup.readOnly },
                                      attrs: {
                                        "hide-details": "auto",
                                        label: "Naam",
                                        placeholder: "naam",
                                        "persistent-placeholder": "",
                                        outlined: !_setup.readOnly,
                                        "error-messages": errors,
                                        readonly: _setup.readOnly,
                                      },
                                      model: {
                                        value: _setup.selectedData.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.selectedData,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "selectedData.name",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Omschrijving",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.description,
                              callback: function ($$v) {
                                _vm.$set(
                                  _setup.selectedData,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "selectedData.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                { attrs: { fluid: "", "pt-6": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _setup.readOnly
                        ? _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.disableSave },
                                  on: {
                                    click: function ($event) {
                                      return _setup.onEdit()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-pencil")]
                                  ),
                                  _vm._v(" Wijzigen "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.disableSave },
                                  on: {
                                    click: function ($event) {
                                      return _setup.onSave()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-checkbox-marked-circle")]
                                  ),
                                  _vm._v(" Opslaan "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_setup.readOnly,
                              expression: "!readOnly",
                            },
                          ],
                          staticClass: "form-group",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Annuleren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }