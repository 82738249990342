import { render, staticRenderFns } from "./PermissionDetails.vue?vue&type=template&id=10ad1c22"
import script from "./PermissionDetails.vue?vue&type=script&lang=ts&setup=true"
export * from "./PermissionDetails.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10ad1c22')) {
      api.createRecord('10ad1c22', component.options)
    } else {
      api.reload('10ad1c22', component.options)
    }
    module.hot.accept("./PermissionDetails.vue?vue&type=template&id=10ad1c22", function () {
      api.rerender('10ad1c22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/iam/PermissionDetails.vue"
export default component.exports